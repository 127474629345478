<template>
  <div>
    <v-container>
      <v-row class="mb-4">
        <h2>Information du Départ</h2>
      </v-row>

      <v-simple-table>
        <thead>
          <tr v-for="field in fields" :key="field.inputKey">
            <td style="width: 260px">
              {{ field.name }}
            </td>
            <td>
              <v-text-field v-model="inputs[field.inputKey]" />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'uOttawa1321Introduction',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        title: null,
        author: null,
        partner: null,
        demonstrator: null,
        date: null,
      },
      fields: [
        {name: "Titre de l'expérience: ", inputKey: 'title'},
        {name: "Nom(s) d(es)'auteur(s): ", inputKey: 'author'},
        {name: 'Nom du partenaire (si applicable): ', inputKey: 'partner'},
        {name: 'Nom du TA (démonstrateur): ', inputKey: 'demonstrator'},
        {name: "Date que l'expérience a été effectueéé: ", inputKey: 'date'},
      ],
    };
  },
};
</script>

<style scoped></style>
